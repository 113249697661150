.input {
  height: 40px;
  width: 250px;
  overflow: hidden;
  font-weight: 600;
}
.label{
  font-weight: 600;
}
option {
  font-weight: 600;
}
.input:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #f3a01c;
}
form {
  margin: 0 auto;
}
.left {
  text-align: left;
}
.flex {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding-right: 300px;
  font-weight: 600;
}
.query {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.disease {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
}
.absolute {
  margin: 0 !important;
  position: absolute;
  top: -18px;
  left: 20px;
  font-size: 18px;
  line-height: 30px;
  background-color: #fff;
  font-family: "Times New Roman", Times, serif;
  font-size: larger;
}
.para {
  margin-top: 22px;
  font-weight: 600;
}
p {
  padding-left: 30px;
  font-weight: 600;
}
.justify {
  text-align: justify;
  padding-right: 30px;
}
.consent {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-weight: 600;
}
.primary {
  background-color: #f3a01c;
  border: 1px solid #f3a01c;
}
.primary:hover {
  background-color: #f3a01c;
  border: 1px solid #f3a01c;
}

.primary:active {
  background-color: #f3a01c;
  border: 1px solid #f3a01c;
}
.primary:focus {
  background-color: #f3a01c;
  border: 1px solid #f3a01c;
}
.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f3a01c;
  --bs-btn-border-color: #f3a01c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #f3a01c;
  --bs-btn-hover-border-color: #f3a01c;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #f3a01c;
  --bs-btn-active-border-color: #f3a01c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f3a01c;
  --bs-btn-disabled-border-color: #f3a01c;
  font-weight: 600;
}

.form-check-input:checked {
  background-color: #f3a01c;
  border-color: #f3a01c;
  font-weight: 600;
}
.mandatory {
  color: red;
  font-weight: bolder;
}

@media (max-width: 995px) {
  .query {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .check {
    padding-left: 30px;
    font-weight: 600;
  }
}

@media (min-width: 359px) {
  input {
    width: 300px;
  }
}
@media (max-width: 990px) {
  .input {
    height: 40px;
    width: 440px;
    overflow: hidden;
  }
}
@media (max-width: 495px) {
  .input {
    height: 40px;
    width: 360px;
    overflow: hidden;
  }
}

@media (max-width: 414px) {
  .input {
    width: 250px;
    overflow: hidden;
  }
}
@media (max-width: 414px) {
  .input {
    width: 250px;
    overflow: hidden;
  }
}
@media (max-width: 360px) {
  .input {
    width: 250px;
    overflow: hidden;
  }
  .newotp{
    width:200px;
  }
}

@media (max-width: 300px) {
  .input {
    width: 225px;
    overflow: hidden;
  }
}
@media (max-width: 287px) {
  .input {
    width: 225px;
    overflow: hidden;
  }
}
@media (max-width: 275px) {
  .input {
    width: 190px;
    overflow: hidden;
  }
  
}