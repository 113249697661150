/** @format */

.form-input-container {
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin: 5px 10px;

  .form-input {
    height: 34px;
    width: 100%;
    border: 1px solid #aaa;
    border-radius: 4px;
    margin: 10px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;

    &:focus,
    &:hover {
      border: 2px solid #ffd580;
      box-shadow: 0 0 8px 0 #ffd580;
    }
  }

  ::placeholder {
    color: #dcdcdc;
  }

  .rich-form-input {
    height: 60px;
  }

  .long-form-input {
    max-width: 100%;
  }
  .short-form-input {
    width: 25%;
  }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
  .form-input-container {
    margin: 5px 5px;
  }

  .form-input {
    width: 100%; /* Full-width input for smaller screens */
  }

  .short-form-input {
    width: 100%; /* Short input adapts to full width on mobile */
  }

  .rich-form-input {
    height: 50px; /* Adjust height for compact display */
  }
}
