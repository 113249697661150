body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.1mm;
}
.margin-top1 {
  margin-top: 20px;
}

@media (max-width: 990px) {
  .input {
    height: 40px;
    width: 440px;
    overflow: hidden;
  }
  .margin-top1 {
    margin-top: 20px;
  }
}
@media (max-width: 495px) {
  .input {
    height: 40px;
    width: 360px;
    overflow: hidden;
  }
  .margin-top1 {
    margin-top: 20px;
  }
}
