.appointment {
  background-color: #f3a01c;
  width: auto;
  font-weight: 600;
}

.appointment:focus {
  background-color: #f3a01c;
}
.appointment:active {
  background-color: #f3a01c;
}
.appointment:hover {
  background-color: #f3a01c;
}
.list_of_buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-weight: 600;
}
.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f3a01c;
  --bs-btn-border-color: none;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #f3a01c;
  --bs-btn-hover-border-color: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #f3a01c;
  --bs-btn-active-border-color: none;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f3a01c;
  --bs-btn-disabled-border-color: none;
  font-weight: 600;
}

.container-1 {
  display: flex;

  align-items: center;
  gap: 50px;
  height: 260px;
  width: 1000px;
  margin: 0px auto 0px auto;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 5px 5px 5px #eeedec;
}
.images {
  width: 200px;
  height: 200px;
  border-radius: 10%;
  object-fit: cover;
}
.button-slots {
  width: 1000px;
  margin: 10px auto 10px auto;
}
.doctor-image {
  width: 200px;
  height: 200px;
  border-radius: 10%;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: auto;
}
.react-datepicker__input-container input {
  border: 1px solid #f3a01c;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  font-weight: 600;
  padding: 5px;
}
.react-datepicker__input-container input:hover {
  transform: scale(1.02);
  transition: 0.1s;
}
.button-next {
  border-radius: 15px;
}
.button-next:hover {
  transform: scale(1.02);
  transition: 0.1s;
}
.text-align {
  text-align: left;
  font-weight: 700;
}
.slotdate_slot {
  margin: 5px 0px auto 0px;
}
.flex4 {
  display: flex;
  gap: 15px;
  align-items: center;
}
.flex5 {
  display: flex;
  /* gap: calc(100% / 5); */
  align-items: center;
  justify-content: center;
}
.select {
  border: 1px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  font-weight: 600;
  padding: 5px;
  height: 40px;
}

.select:hover {
  transform: scale(1);
  transition: 0.1s;
}
.select:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #f3a01c;
}
.profile-visit-consult {
  background-color: white;
  color: #f3a01c;
  border: 1px solid #f3a01c;
  /* border-ri: 5px; */
  cursor: pointer;

  padding: 5px;
  outline: none;
  box-shadow: none !important;
}
.profile-visit-consult:hover {
  transform: scale(1.01);
  transition: 0.1s;
}

.width {
  width: 600px;
  margin: 0px auto 30px auto;
}
/* .form-select {
  background-image: none;
} */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 140px;
}
.date-picker {
  padding: 30px;
}
.example-custom-input {
  background: url("../../images//Calendar.png") no-repeat right;
  background-size: 24px;
  width: 240px;
  margin: 5px;
  color: #f3a01c;
  outline: none;
  box-shadow: none !important;
  /* height: 24px; */
}

.cal_icon {
  width: 35px;
  height: 35px;
}
.modal {
  --bs-modal-width: 400px;
}

@media (max-width: 1025px) {
  .width {
    width: 600px;
    margin: 10px auto 10px auto;
  }
  .container-1 {
    width: 90%;
    margin: 0px auto 0px auto;
  }
}
@media (max-width: 790px) {
  .container-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: auto;
    flex-wrap: wrap;
    height: auto;
    margin: auto;
    border-radius: 20px;
    padding: 10px;
  }
  .profile-visit-consult {
    border-radius: 10px;
  }
  .images {
    width: 250px;
    height: 250px;
    border-radius: 10%;
    object-fit: cover;
    /* margin:auto; */
    /* flex-grow: 1; */
  }
  .doctor-image {
    width: 250px;
    height: 250px;
    border-radius: 10%;
  }
  .flex4 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  }
  .flex5 {
    display: flex;
    flex-direction: column;
    /* gap: calc(100% / 5); */
    align-items: flex-start;
    align-content: flex-start;
  }
  .width {
    width: 550px;
    margin: 10px auto 10px auto;
  }
  .button-slots {
    width: 90%;
    margin: 10px auto 10px auto;
  }
  .list_of_buttons {
    flex-direction: column;
  }
  .margin-top0 {
    margin-top: 0px;
  }
  .appointment {
    width: auto;
  }
}
.newotp {
  width: 250px;
}

@media (max-width: 900px) {
  .container-1 {
    width: 90%;
    margin: 0px auto 0px auto;
  }
  .width {
    width: 90%;
    margin: 10px 10px 10px 10px;
  }
  .button-slots {
    width: 90%;
    margin: 10px auto 10px auto;
  }
  .list_of_buttons {
    flex-direction: column;
  }
  .margin-top0 {
    margin-top: 0px;
  }
  .appointment {
    width: auto;
  }
}

@media (max-width: 500px) {
  .width {
    width: 95%;
    margin: 10px auto 10px auto;
  }
  .button-slots {
    width: 90%;
    margin: 10px auto 10px auto;
  }
  .list_of_buttons {
    flex-direction: column;
  }
  .margin-top0 {
    margin-top: 0px;
  }
  .appointment {
    width: auto;
  }
  .select5 {
    width: 95%;
  }
}
@media (max-width: 360px) {
  .newotp {
    width: 95%;
  }
}

@media (max-width: 990px) {
  .slot-visibility {
    margin-bottom: 20px;
    display: block;
  }
  .list_of_buttons {
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
  .margin-top0 {
    margin-top: 0px;
  }
  .appointment {
    width: auto;
  }
}
