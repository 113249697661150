.terms {
  background: #f2f2f2;
  padding: 1% 0;
  color: #ccc;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin: auto;
  width: auto;
  font-family: "LatoWeb";
}
a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

a:hover {
  color: #f3a01c;
}
.allrights {
  text-align: center;
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
/* .container1 {
  margin-bottom: 150px;
} */
@media (max-width: 900px) {
  .container1 {
    margin-bottom: 150px;
  }
}

@media (max-width: 500px) {
  .container1 {
    margin-bottom: 175px;
  }
}

@media (max-width: 990px) {
  .container1 {
    margin-bottom: 175px;
  }
}
